

















































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import type { NavigationGuardNext, Route } from "vue-router";
import type { CancelTokenSource } from "axios";
import axios from "axios";
import { isEqual } from "lodash-es";
import VirtualList from "vue-virtual-scroll-list";
import {
  DefaBaseButton,
  DefaTransitionExpand,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { loadingModule } from "@/store/modules/loading-module";
import InstallationListItem from "@/components/installation-list/InstallationListItem.vue";
import InstallationListFilterSearch from "@/components/installation-list/InstallationListFilterSearch.vue";
import InstallationListFilterOrderStatus from "@/components/installation-list/InstallationListFilterOrderStatus.vue";
import type { InstallationListItemType, OrderStatusForIPV } from "@/types";
import { ROUTE_NAMES } from "@/router";
import { orderStatusesForIPVDefault } from "@/options";
import { Nullable } from "@defa-as/utils";
import { getInstallations } from "@/http/requests";

@Component({
  components: {
    DefaBaseButton,
    InstallationListFilterOrderStatus,
    VirtualList,
    InstallationListFilterSearch,
    DefaTransitionFadeOutIn,
    DefaTransitionExpand,
  },
})
export default class ViewInstallationList extends Vue {
  @Prop({ default: "" }) readonly searchTerm!: string;
  @Prop({ default: () => [] }) readonly orderStatuses!: OrderStatusForIPV[];
  @Ref() readonly virtualList!: InstanceType<typeof VirtualList>;
  installations: InstallationListItemType[] = [];
  cancelToken: Nullable<CancelTokenSource> = null;
  filters = {
    searchTerm: this.searchTerm,
    orderStatuses: this.orderStatuses,
  };
  filtersVisible = true;
  pagination = {
    page: 1,
    totalPages: -1,
  };

  get loadingModule() {
    return loadingModule;
  }

  get hasInstallations() {
    return Boolean(this.installations.length);
  }

  get installationListData() {
    return {
      itemComponent: InstallationListItem,
      items: this.installations,
    };
  }

  get routeQuery() {
    if (this.filters.searchTerm || this.filters.orderStatuses.length) {
      return {
        ...(this.filters.searchTerm
          ? { searchTerm: this.filters.searchTerm }
          : null),
        ...(this.filters.orderStatuses.length
          ? {
              orderStatuses: this.filters.orderStatuses,
            }
          : null),
      };
    }
    return null;
  }

  get filtersToggleText() {
    return this.$t(
      `installationList.filtersToggle.${this.filtersVisible ? "hide" : "show"}`
    );
  }

  async created() {
    await this.loadInstallations();
  }

  async onDebouncedInput() {
    if (!isEqual(this.$route.query, this.routeQuery)) {
      await this.$router.push({
        name: ROUTE_NAMES.INSTALLATION.LIST,
        ...(this.routeQuery && { query: this.routeQuery }),
      });
    }
  }

  async onChangeFilterOrderStatuses(orderStatuses: OrderStatusForIPV[]) {
    this.filters.orderStatuses = orderStatuses;
    if (!isEqual(this.$route.query, this.routeQuery)) {
      await this.$router.push({
        name: ROUTE_NAMES.INSTALLATION.LIST,
        ...(this.routeQuery && { query: this.routeQuery }),
      });
    }
  }

  async onClearSearchTerm() {
    if (this.filters.searchTerm) {
      this.filters.searchTerm = "";
      await this.$router.push({
        name: ROUTE_NAMES.INSTALLATION.LIST,
        ...(this.routeQuery && { query: this.routeQuery }),
      });
    }
  }

  async onBottomReached() {
    if (this.pagination.page < this.pagination.totalPages) {
      this.pagination.page++;
      await this.loadInstallations({
        append: true,
      });
    }
  }

  async loadInstallations(
    { append } = {
      append: false,
    }
  ) {
    this.loadingModule.setUpdateLoading();
    try {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      const { data: installations, meta } = await getInstallations(
        this.pagination.page,
        this.filters.searchTerm,
        this.filters.orderStatuses,
        this.cancelToken.token
      );
      this.pagination.totalPages = meta.pagination.total_pages;
      this.installations = [
        ...(append ? this.installations : []),
        ...installations,
      ];
      this.loadingModule.unsetUpdateLoading();
      // Don't scroll to top when loading additional orders
      if (!append) {
        this.virtualList.scrollToIndex(0);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.loadingModule.unsetUpdateLoading();
      }
    }
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  beforeRouteEnter(to: Route, _: Route, next: NavigationGuardNext) {
    if (Array.isArray(to.query.orderStatuses)) {
      next();
      return;
    }
    next({
      path: to.path,
      query: {
        ...to.query,
        // A single "orderStatus" will be of type "string", so we need to convert it to an Array as the prop #orderStatuses expects an Array
        orderStatuses: to.query.orderStatuses
          ? [to.query.orderStatuses]
          : orderStatusesForIPVDefault,
      },
    });
  }

  beforeRouteLeave(_: Route, __: Route, next: NavigationGuardNext) {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.loadingModule.unsetUpdateLoading();
    }
    next();
  }

  @Watch("$route")
  async onRouteChange() {
    await this.loadInstallations();
  }
}
