








































































import { Component, Prop, Vue } from "vue-property-decorator";
import { userModule } from "@/store/modules/user-module";
import { formatDate, isElliOrder, OrderLine } from "@defa-as/utils";
import type { InstallationListItemType } from "@/types";

@Component
export default class InstallationListItem extends Vue {
  @Prop({ required: true }) readonly source!: InstallationListItemType;

  get orderStatusTranslated() {
    return this.$t(`shared.options.orderStatus.${this.source.orderStatus}`);
  }

  get link() {
    return `/${this.source.id}/${this.source.secret}`;
  }

  get isAdmin() {
    return userModule.isAdmin;
  }

  get skuOrAxNumber() {
    return (orderLine: OrderLine) => {
      if (isElliOrder(this.source.retailer.name)) {
        return orderLine.sku;
      }
      return orderLine.axNumber;
    };
  }

  get installationScheduledFormatted() {
    if (this.source.state.installationScheduled.date) {
      return formatDate(this.source.state.installationScheduled.date);
    }
    return null;
  }

  get customerCarDeliveryDateFormatted() {
    if (this.source.customerCarDeliveryDate) {
      return formatDate(this.source.customerCarDeliveryDate.date);
    }
    return null;
  }
}
