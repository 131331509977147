import { getOrderStatuses, OrderStatus } from "@defa-as/utils";
import { OrderStatusForIPV } from "@/types";

export const orderStatusesForIPV: OrderStatusForIPV[] = getOrderStatuses().filter(
  (status: OrderStatus): status is OrderStatusForIPV =>
    ![
      "orderReceived",
      "installationPartnerChosen",
      "remoteHomeCheckSent",
      "remoteHomeCheckCompleted",
      "deleted",
    ].includes(status)
);

export const orderStatusesForIPVDefault = orderStatusesForIPV.filter(
  (status) => !["done", "revoked"].includes(status)
);
