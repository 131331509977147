



































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput, DefaTransitionFadeOutIn } from "@defa-as/components";

const FADE_DELAY = 0.5;

@Component({
  components: {
    DefaTransitionFadeOutIn,
    DefaBaseInput,
  },
  model: {
    prop: "modelValue",
  },
})
export default class InstallationListFilterSearch extends Vue {
  @Prop({ required: true }) readonly modelValue!: string;

  get searchInputStyle() {
    return {
      "font-weight": "bold",
    };
  }

  get value() {
    return this.modelValue;
  }

  set value(value: string) {
    this.$emit("input", value);
  }

  get iconCrossEnterDelay() {
    return FADE_DELAY;
  }

  onClickIconCross() {
    this.$emit("clear:search-term");
  }
}
