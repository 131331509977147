












import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseCheckboxMulti } from "@defa-as/components";
import type { OrderStatusForIPV } from "@/types";
import { orderStatusesForIPV } from "@/options";

@Component({
  components: {
    DefaBaseCheckboxMulti,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
})
export default class InstallationListFilterOrderStatus extends Vue {
  @Prop({ required: true }) readonly modelValue!: OrderStatusForIPV[];
  readonly orderStatusOptions: OrderStatusForIPV[] = orderStatusesForIPV;

  get value() {
    return this.modelValue;
  }

  set value(newValues: OrderStatusForIPV[]) {
    this.$emit("change", newValues);
  }
}
